import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

interface AvatarProps {
    userImage: string | null;
    userName: string | null;
    setUserInfo: any;
    handleSignOut: any;
    userInfo: any
}

const Avatar = ({userImage, userName, setUserInfo, handleSignOut, userInfo}: AvatarProps) => {
    const [avatarSrc, setAvatarSrc] = useState(userImage || null);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const { t } = useTranslation();

    const initials = userName
        ? userName.split(' ').map((n)=>n[0]).join('')
        : ''; // retourne une chaîne vide si userName est null ou vide


    const avatarPlaceholder = (
        <div style={{backgroundColor: '#984242', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '40px', height: '40px'}}>
            <p style={{margin: '0', fontSize: '18px'}}>{initials}</p>
        </div>
    );

    const handleImageError = () => {
        setAvatarSrc(null); // cela fera en sorte que l'avatar par défaut soit affiché
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleShowUserInfo = () => {
        // Handle show user info logic here
    };

    return (
        <div onClick={toggleDropdown} style={{ position: 'relative', paddingRight: '23px' }}>
            {avatarSrc ?
                <img src={avatarSrc} onError={handleImageError} alt="User Avatar" style={{borderRadius: '50%', width: '10vw', maxWidth: '40px', height: 'auto'}} /> :
                avatarPlaceholder
            }
            {dropdownVisible && (
                <div style={{ position: 'absolute', top: '50px', right: '0', backgroundColor: '#fff', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: '1' }}>
                    <p style={{ padding: '10px', cursor: 'pointer' }} onClick={handleShowUserInfo}>{t('myAccount')}</p>
                    <p style={{ padding: '10px', cursor: 'pointer' }} onClick={handleSignOut}>{t('signOut')}</p>
                </div>
            )}
        </div>
    )
}

export default Avatar;
