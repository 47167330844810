import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {MathJaxContext, MathJax} from "better-react-mathjax";
import {useTranslation} from "react-i18next";
import Prism from "prismjs";
import 'prismjs/themes/prism-solarizedlight.css';


interface Record {
    create_date: string;
    images_numbers: number[];
    pages: number;
    requirement: string | null;
    answer: string;
}

interface Upload {
    image_urls: string[];
    records: Record[];
    key_origin: string;
    title: string;
}

interface AccordionProps {
    upload: Upload;
}

const AccordionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const AccordionTitle = styled.div`
    width: 90%; /* Occupy the full width available */
    padding: 10px;
    background-color: #f2f5fc;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-bottom: 5px;
`;

const AccordionContent = styled.div`
    max-width: 80%;
    margin-top: 5px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
`;

const ImageContainer = styled.div`
    display: inline-block;
    flex-direction: column;
    align-items: center;
`;

const ImagePreviewCss = styled.img`
  width: 80px;
  height: 100px;
  margin-right: 10px;
  object-fit: cover;
  cursor: pointer;
`;

const RecordContainer = styled.div`
  margin-top: 10px;
`;

const RecordTitle = styled.div`
  margin-bottom: 5px;
`;

const RecordContent = styled.div`
  margin-bottom: 10px;
    max-width: 80%;
    margin-top: 5px;
    padding: 10px;
    background-color: #ede8e8;
    border: 1px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    
`;

const RecordAnswer = styled.div`
  white-space: pre-wrap;
`;

const ModalContainer = styled.div<{ isVisible: boolean }>`
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1;
`;

const ModalContent = styled.div`
    max-width: 80%; /* Set the maximum width as desired */
    max-height: 80%; /* Set the maximum height as desired */
    overflow: auto;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
`;

const CloseButton = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
`;

const ModalImage = styled.img`
    max-width: 80%; /* Ensure the image does not exceed the container width */
    max-height: 80%;
    margin-right: 10px;
    margin-bottom: 10px;
    object-fit: cover;
    cursor: pointer;
`;

const SpanTitle = styled.span`
    font-weight: bold;
    background-color: #fbf4f4;
`;

interface ModalProps {
    onClose: () => void;
    imageUrl: string;
    alt: string;
}

const Modal: React.FC<ModalProps> = ({ onClose, imageUrl, alt }) => {
    return (
        <ModalContainer isVisible={imageUrl !== ''} onClick={onClose}>
            <ModalContent>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <ModalImage src={imageUrl} alt={alt} />
            </ModalContent>
        </ModalContainer>
    );
};

interface ImagePreviewProps {
    url: string;
    alt: string;
    index: number;
    onClick?: (url: string) => void;
}

const DownloadLink = styled.a`
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
`;
const ImagePreview: React.FC<ImagePreviewProps> = ({ url, alt, index , onClick }) => {
    const [showModal, setShowModal] = useState(false);

    const { t } = useTranslation();

    const handleImageClick = () => {
        setShowModal(true);
        if (onClick) {
            onClick(url);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ImageContainer>
                <ImagePreviewCss
                    src={url}
                    alt={alt}
                    onClick={handleImageClick}
                    style={{cursor: 'pointer'}}
                />

                <DownloadLink href={url}>
                    {t('page')} {index}
                </DownloadLink>
            </ImageContainer>
            {showModal && (
                <Modal onClose={closeModal} imageUrl={url} alt={alt} />
            )}
        </>
    );
};

const Accordion: React.FC<AccordionProps> = ({ upload }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();
    const language = localStorage.getItem('language');
    const isRtl = language === 'ar';

    const isImage = (filename: string) => {
        const imageExtensions = [".png", ".jpeg", ".jpg", ".gif", ".bmp"]; // Ajoutez les extensions d'image nécessaires
        const ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
        return imageExtensions.includes("." + ext.toLowerCase());
    };

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const processText = (text: string) => {

        const codeParts = text.replace(/```(\w+)([\s\S]*?)```/gs, (match, lang, code) => {
            return `<pre class="language-${lang}"><code className="language-${lang}">${code}</code></pre>`;
        });

        const code = codeParts.replace(/`([\s\S]*?)`/gs, (match, p1) => {
            return `<code>${p1}</code>`;
        });

        const styledText = code.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
            return `<strong>${p1}</strong>`;
        });

        const textWithLineBreaks = styledText.replace(/(\n)/g, '<br />');

        return textWithLineBreaks;
    };

    return (
        <AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
            <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
                <div>{upload.title}</div>
                <div>{isOpen ? '-' : '+'}</div>
            </AccordionTitle>
            {isOpen && (
                <AccordionContent>
                    <div>
                        <div>
                            <SpanTitle>{t('originalDocument')}:</SpanTitle> <a
                                href={upload.key_origin}>{upload.title}</a>
                        </div>
                        <br/>

                        {!isImage(upload.title) && (
                            <>
                                <SpanTitle>{t('generatedImagesList')} :</SpanTitle> <br/><br/>
                                <ImageContainer>
                                    {upload.image_urls.map((url, index) => (
                                        <ImagePreview key={url} url={url} alt="Image" index={index + 1} />
                                    ))}
                                </ImageContainer>
                            </>
                        )}
                    </div>
                    <RecordContainer>
                        {upload.records.map((record, index) => (
                            <RecordContent key={index}>
                                <RecordTitle><SpanTitle>{t('requestDate')}:</SpanTitle> {record.create_date}</RecordTitle>
                                <RecordTitle><SpanTitle>{t('usedPagesList')}:</SpanTitle> {record.images_numbers.map((num) => `${t('page')} ${num}, `)}</RecordTitle>
                                <br/>
                                <RecordTitle><SpanTitle>{t('response')}:</SpanTitle></RecordTitle>
                                <MathJaxContext>
                                    <MathJax dynamic>
                                        <RecordAnswer>
                                        <div dangerouslySetInnerHTML={{__html: processText(record.answer)}}
                                             style={{direction: isRtl ? 'rtl' : 'ltr'}}></div>
                                        </RecordAnswer>
                                    </MathJax>
                                </MathJaxContext>

                            </RecordContent>
                        ))}
                    </RecordContainer>
                </AccordionContent>
            )}
        </AccordionContainer>
    );
};

export default Accordion;
