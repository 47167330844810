import React, {useState} from 'react';
import styled from '@emotion/styled';
import {Link} from "react-router-dom";
import {Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import { ReactComponent as SchoolIcon } from './icons/school_fill1_24px.svg';
import { ReactComponent as HomeIcon } from './icons/home_fill1_24px.svg';
import { ReactComponent as AddCircleIcon } from './icons/add_circle_fill1_24px.svg';
import { ReactComponent as VideoLibraryIcon } from './icons/video_library_fill1_24px.svg';
import {FooterItem, FooterLabel} from "./CardStyles";

const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #dde5f9;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #000000;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 0;
    @media (max-width: 768px) {
    }
`;

const Footer: React.FC = () => {
    const [createAnchorEl, setCreateAnchorEl] = useState<null | HTMLElement>(null);
    const [historyAnchorEl, setHistoryAnchorEl] = useState<null | HTMLElement>(null);
    const [learningAnchorEl, setLearningAnchorEl] = useState<null | HTMLElement>(null);

    const { t } = useTranslation();

    const handleSchoolIconClick: React.MouseEventHandler<SVGSVGElement> = (event) => {
        setCreateAnchorEl(event.currentTarget as unknown as HTMLElement);
    };

    const handleHistoryIconClick: React.MouseEventHandler<SVGSVGElement> = (event) => {
        setHistoryAnchorEl(event.currentTarget as unknown as HTMLElement);
    };

    const handleLearningIconClick: React.MouseEventHandler<SVGSVGElement> = (event) => {
        setLearningAnchorEl(event.currentTarget as unknown as HTMLElement);
    };

    const handleCreateClose = () => {
        setCreateAnchorEl(null);
    };

    const handleHistoryClose = () => {
        setHistoryAnchorEl(null);
    };

    const handleLearningClose = () => {
        setLearningAnchorEl(null);
    };

    return (
        <FooterContainer>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FooterItem>
                    <HomeIcon />
                    <FooterLabel>{t('home')}</FooterLabel>
                </FooterItem>
            </Link>
            <FooterItem>
                <VideoLibraryIcon onClick={handleHistoryIconClick} />
                <FooterLabel>{t('history')}</FooterLabel>
                <Menu
                    id="history-menu"
                    anchorEl={historyAnchorEl}
                    keepMounted
                    open={Boolean(historyAnchorEl)}
                    onClose={handleHistoryClose}
                >
                    <MenuItem onClick={handleHistoryClose} component={Link} to="/uploads" style={{ fontFamily: 'serif' }}>{t('uploads')}</MenuItem>
                    <MenuItem onClick={handleHistoryClose} component={Link} to="/conversations" style={{ fontFamily: 'serif' }}>{t('conversations')}</MenuItem>
                </Menu>
            </FooterItem>
            <FooterItem>
                <AddCircleIcon onClick={handleSchoolIconClick} ></AddCircleIcon>
                <FooterLabel>{t('assistance')}</FooterLabel>
                <Menu
                    id="create-menu"
                    anchorEl={createAnchorEl}
                    keepMounted
                    open={Boolean(createAnchorEl)}
                    onClose={handleCreateClose}
                >
                    <MenuItem onClick={handleCreateClose} component={Link} to="/scan" style={{ fontFamily: 'serif' }}>{t('scan')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/upload" style={{ fontFamily: 'serif' }}>{t('upload')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/text" style={{ fontFamily: 'serif' }}>{t('specificQuestion')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/text-conversation" style={{ fontFamily: 'serif' }}>{t('textConversation')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/voice-conversation" style={{ fontFamily: 'serif' }}>{t('voiceConversation')}</MenuItem>
                </Menu>
            </FooterItem>
            <FooterItem>
                <SchoolIcon onClick={handleLearningIconClick} />
                <FooterLabel>{t('learning')}</FooterLabel>
                <Menu
                    id="learning-menu"
                    anchorEl={learningAnchorEl}
                    keepMounted
                    open={Boolean(learningAnchorEl)}
                    onClose={handleLearningClose}
                >
                    <MenuItem onClick={handleLearningClose} component={Link} to="/dialogue" style={{ fontFamily: 'serif' }}>{t('generateConversation')}</MenuItem>
                </Menu>
            </FooterItem>

        </FooterContainer>
    );
};

export default Footer;
