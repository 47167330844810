import React, { createContext, useContext, useState } from 'react';

interface ListContextType {
    list: string[];
    setList: (list: string[]) => void; // Fonction pour mettre à jour la liste entière
}

const ListContext = createContext<ListContextType | undefined>(undefined);

export const useList = () => {
    const context = useContext(ListContext);
    if (!context) {
        throw new Error('useList must be used within a ListProvider');
    }
    return context;
};

interface ListProviderProps {
    children: React.ReactNode;
}

export const ListProvider: React.FC<ListProviderProps> = ({ children }) => {
    const [list, setListState] = useState<string[]>([]);

    const setList = (newList: string[]) => {
        setListState(newList);
    };

    return (
        <ListContext.Provider value={{ list, setList }}>
            {children}
        </ListContext.Provider>
    );
};
