import React, {useState} from 'react';
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import i18n from "./i18n";
import YouTubeEmbed from "./YoutubeEmbedded";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const HomeTitle = styled.h1`
  font-size: 2rem;
  text-align: center;
  color: #000000;
  margin-bottom: 1.5rem;
  padding-top: inherit;
`;

const HomeText = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #000000;
    
  @media (max-width: 800px) {
      font-size: 1rem;
  }
`;

interface TabProps {
    active: boolean;
}

const TabViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
`;

const TabList = styled.div`
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
`;

const Tab = styled.div<TabProps>`
    cursor: pointer;
    padding: 5px;
    margin-right: 10px;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    ${(props) => (props.active ? 'background-color: #e2eafd;' : '')}
    font-size: small;
    @media (max-width: 600px) {
        font-size: small;
    }
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const VideoPanel = styled.div<TabProps>`
    display: none;
    ${(props) => props.active && 'display: block;'}
`;

const Home: React.FC = () => {
    const { t } = useTranslation();
    const homeText = t('homeText') || '';
    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';
    const ids = [1, 2];
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    return (
        <HomeContainer>
            <HomeTitle>
            <div
                dangerouslySetInnerHTML={{__html: t('homeTitle') as string}}
                style={{direction: isRtl ? 'rtl' : 'ltr'}}/>
            </HomeTitle>

            {(currentLanguage === 'fr' || currentLanguage === 'en') && (
                <TabViewContainer>
                    <TabList>
                        {ids.map((videoId, index) => (
                            <Tab
                                key={videoId}
                                active={selectedTabIndex === index}
                                onClick={() => setSelectedTabIndex(index)}
                            >
                                {t(`videoLabel${videoId}`)}
                            </Tab>
                        ))}
                    </TabList>

                    <VideoContainer>
                        {ids.map((videoId, index) => (
                            <VideoPanel key={videoId} active={selectedTabIndex === index}>
                                <YouTubeEmbed videoId={t(`videoId${videoId}`)}/>
                            </VideoPanel>
                        ))}
                    </VideoContainer>
                </TabViewContainer>
            )}
            <HomeText style={{direction: isRtl ? 'rtl' : 'ltr'}} dangerouslySetInnerHTML={{__html: homeText}}/>
        </HomeContainer>
    );
};

export default Home;
