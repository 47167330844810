import styled from "@emotion/styled";
import ReactPlayer from "react-player";

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Card = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 20px;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important; // Important is used here because ReactPlayer sets width and height directly
    height: 100% !important;
`;

export const StyledAudioReactPlayer = styled(StyledReactPlayer)`
    height: 50px;
    width: 100% !important;
`;

export const PlayerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 400px;  /* Ajoutez une hauteur minimale */
    max-height: 800px;  /* Ajoutez une hauteur maximale */
`;

export const AudioPlayerContainer = styled(PlayerContainer)`
    min-height: 40px;  /* Ajoutez une hauteur minimale */
    max-height: 120px;  /* Ajoutez une hauteur maximale */
    
`;

export const PlayerWrapper = styled.div`
    position: relative;
    width: 70vw; 
    height: 50vh; 
    border-radius: 30px;
    overflow: hidden;

    @media (max-width: 600px) {
        width: 90vw; // Ajuster la largeur pour les mobiles ici
        height: 30vh; // Ajuster la hauteur pour les mobiles ici
    }
`;

export const AudioPlayerWrapper = styled(PlayerWrapper)`
    height: 80px; // Ajuster la hauteur pour l'audio ici
    @media (max-width: 600px) {
        width: 90vw; // Ajuster la largeur pour les mobiles ici
        height: 10vh; // Ajuster la hauteur pour les mobiles ici
    }
`;

export const StoryInput = styled.textarea`
    flex-grow: 1; 
    margin: 10px 10px;  
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #000000;
    font-family: Space Grotesk,sans-serif;
    width: 80%;
    height: 100px;

    @media (max-width: 600px) {
        width: 90%;
        margin: 0 0 10px 5px;
        font-size: 16px; // Reduce font-size for mobile
    }
`;

export const FooterItem = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`;

export const FooterLabel = styled.span`
    font-size: 12px;
    color: #000000;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

export const InputTextContainer = styled.div`
    display: flex;
    flex-direction: column;  
    align-items: center; 
    justify-content: center;

    @media (max-width: 600px) {
        align-items: center;
    }

    @media (min-width: 1024px) { 
        align-items: center;
    }
`;
export const InputText = styled.input`
    flex-grow: 1; 
    margin: 10px 5px;  
    font-size: 12px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #4a148c;
    font-family: Space Grotesk,sans-serif;
    width: 270%;

    @media (max-width: 600px) {
        width: 230%;
        margin: 0 0 10px 5px;
        font-size: 10px; // Reduce font-size for mobile
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    @media (min-width: 600px) {
        flex-direction: row;
    }
`;

export const Button = styled.button`
    align-self: center;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 7px 7px;
    background: #dde5f9;  // purple background
    color: black;  // white text
    border-radius: 7px;  // rounded border
    font-family: Space Grotesk,sans-serif;
    cursor: pointer;  // change cursor on hover
    transition: background-color 0.3s;  // smooth color transition
    margin-right: 10px;
    border: 1px solid #4a148c;
    // change background color on hover
    &:hover {
        background-color: #dde5f9;  // darker purple
    }

    &:last-child {
        margin-bottom: 0;  // remove margin from the last button
    }

    @media (min-width: 600px) {
        margin-right: 10px;
        margin-bottom: 0;
    }
    
    &:disabled {
        background-color: #dedada;  // grey background when disabled
        border-color: #dedada;
        cursor: not-allowed;  // change cursor when disabled
    }
`;

export const ResultContainer = styled.div`
    margin-top: 20px;
    width: 90%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
`;

export const ResultHeading = styled.h2`
    font-size: 20px;
    color: #4a148c;  // purple
    font-family: Space Grotesk,sans-serif;
`;


export const ErrorMessage = styled.h4`
    font-size: 16px;
    color: red; 
    justify-content: center;
    align-items: center;
`;

export const SatisfiedMessage = styled.div`
    font-size: 14px;
    color: black; 
    justify-content: center;
    align-items: center;
    background-color: #efdcb5;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
`;
