import React, {useState} from "react";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ReactDOM from "react-dom";
import Footer from "./Footer";
import MediaCreator from "./MediaCreator";
import Header from "./Header";
import {ShakeButtonContext} from "./ShakeButtonContext";
import Home from "./Home";
import UploadList from "./UploadList";
import VoiceConversation from "./VoiceConversation";
import TextConversation from "./TextConversation";
import Dialogue from "./Dialogue";
import DialogueList from "./DialogueList";
import {ListProvider} from "./store/ListContext";


const clientId = process.env.REACT_APP_CLIENT_ID || '606303163032-qihpuc49c8habhivuh4tf06olpq6t1qf.apps.googleusercontent.com';

const App = () => {

    const [shouldShakeButton, setShouldShakeButton] = useState(false);

    const shakeButton = () => {
        setShouldShakeButton(true);
        setTimeout(() => setShouldShakeButton(false), 10000);  // Stop shaking after 1 second
    }

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <ShakeButtonContext.Provider value={{ shouldShakeButton, setShouldShakeButton, shakeButton }}>
                <ListProvider>
                <Router>
                <div>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />

                        <Route path="/scan" element={<MediaCreator mediaType='text' sourceType='scan' />} />
                        <Route path="/upload" element={<MediaCreator mediaType='text' sourceType='upload' />} />
                        <Route path="/text" element={<MediaCreator mediaType='text' sourceType='text' />} />

                        <Route path="/uploads" element={<UploadList />} />

                        <Route path="/voice-conversation" element={<VoiceConversation />} />
                        <Route path="/text-conversation" element={<TextConversation />} />

                        <Route path="/dialogue" element={<Dialogue />} />
                        <Route path="/conversations" element={<DialogueList />} />

                    </Routes>
                    <Footer />
                </div>
            </Router>
                </ListProvider>
            </ShakeButtonContext.Provider>
        </GoogleOAuthProvider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
export default App;
