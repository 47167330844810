import React, {useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from 'jwt-decode';
import styled from "@emotion/styled";
import {ShakeButtonContext} from "./ShakeButtonContext";
import Accordion from "./Accordion";
import {useTranslation} from "react-i18next";

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

interface Record {
    create_date: string;
    images_numbers: number[];
    pages: number;
    requirement: string | null;
    answer: string;
}

interface Upload {
    image_urls: string[];
    records: Record[];
    key_origin: string;
    title: string;
}

interface GroupedRecords {
    grouped_records: { [key: string]: Upload };
}

const UploadList = () => {
    const [uploads, setUploads] = useState<GroupedRecords['grouped_records']>({ });
    const [showSolution, setShowSolution] = useState<{ [index: number]: boolean }>({});
    const [endOfData, setEndOfData] = useState<boolean>(false);
    const lastKeyRef = useRef(null);  // Utilisez useRef au lieu de useState
    const { shakeButton } = useContext(ShakeButtonContext);
    const loadingRef = useRef<HTMLDivElement>(null);  // Ref pour l'élément de chargement

    const { t } = useTranslation();

    const observer = useRef(new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !endOfData) {
            fetchUploads();  // Charge plus de vidéos lorsque l'élément de chargement est visible et que endOfData est false
        }
    }, {
        threshold: 1  // Déclenche l'observer lorsque 100% de l'élément est visible
    }));


    const fetchUploads = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        type JWTDeCode = {
            sub: string,
            email: string,
            iat: number,
            exp: number
        }

        const decodedToken: JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        try {
            const response = await axiosInstance.get('/upload-List/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startKey: lastKeyRef.current  // Utilisez lastKeyRef.current au lieu de lastKey
                },
            });
            setUploads((prevUploads) => {
                const { grouped_records } = response.data;
                const updatedUploads = { ...prevUploads };

                for (const key in grouped_records) {
                    if (grouped_records.hasOwnProperty(key)) {
                        const upload = grouped_records[key];
                        const updatedRecords = upload.records.map((record: Record) => {
                            if (record.answer) {
                                return {
                                    ...record,
                                    answer: record.answer.replace(/&&&&&&/g, "<br /> <br />").replace(/&&&/g, "<br />"),
                                };
                            } else {
                                return {
                                    ...record,
                                    answer: record.answer,
                                };
                            }

                        });

                        updatedUploads[key] = {
                            ...upload,
                            records: updatedRecords,
                        };
                    }
                }

                return updatedUploads;
            });

            lastKeyRef.current = response.data.last_evaluated_key;  // Mettez à jour lastKeyRef.current au lieu de lastKey

            setEndOfData(response.data.end_of_data);
            // Si endOfData est vrai, supprimez l'IntersectionObserver
            if (response.data.endOfData && loadingRef.current) {
                observer.current.unobserve(loadingRef.current);
            }

        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }

    };

    useEffect(() => {
        fetchUploads().then(() => {
            const currentLoadingRef = loadingRef.current;
            const currentObserver = observer.current;

            if (currentLoadingRef) {
                currentObserver.observe(currentLoadingRef);
            }

            return () => {
                if (currentLoadingRef) {
                    currentObserver.unobserve(currentLoadingRef);
                }
            };
        });
    }, []);


    return (
        <MainContainer>
            {Object.values(uploads).length === 0 ? ( endOfData &&
                <p>{t('noDownloadsAvailable')}</p>
            ) : (
                Object.values(uploads).map((upload, index) => (
                    <Accordion key={index} upload={upload} />
                )))
            }
            {/* Affichez "Loading..." uniquement si endOfData est faux */}
            {!endOfData && <div ref={loadingRef}>Loading...</div>}

        </MainContainer>
    );
};

export default UploadList;
