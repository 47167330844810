import React, {useContext, useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import {ReactComponent as PlayCircleIcon} from './icons/play_circle_24px.svg';
import {ReactComponent as PauseCircleIcon} from './icons/pause_circle_24px.svg';
import {ReactComponent as TranslateIcon} from './icons/translate_fill1_24px.svg';
import {ShakeButtonContext} from "./ShakeButtonContext";

import {useList} from './store/ListContext'
import i18n from "./i18n";

interface AudioPlayerProps {
    url: string;
    sentence: string;
    phrase: string;
    index: number;
    isPlaying: boolean;
    playAudioFromIndex: () => void;
    onEnd: () => void;
    language: string | null;
    lang: string | null;
}

interface Translates {
    [key: number]: {sentence: string, exists: boolean};
}
const AudioPlayer: React.FC<AudioPlayerProps> = ({url, sentence, phrase, isPlaying, index, onEnd, playAudioFromIndex,language, lang}) => {
    const [playing, setPlaying] = useState(false);
    const playerRef = useRef(null);
    const [translates, setTranslates] = useState<Translates>({});
    const [traduction, setTraduction] = useState<boolean>(false);

    const { shakeButton } = useContext(ShakeButtonContext);
    const userLang = localStorage.getItem('language');
    const { list } = useList();
    const currentLanguage = i18n.language;
    const isRtlUser = currentLanguage === 'ar';
    const isRtlTo = lang === 'ar';
    let content;
    let bouton;

    useEffect(() => {
        if (Object.keys(translates).length === 0) {
            const newTranslates: Translates = {};
            list.forEach((sentence:string, ind:number) => {
                newTranslates[ind] = { sentence, exists: false };
            });
            setTranslates(newTranslates);
        }
    }, [list]);

    const handlePlayPause = () => {
        setPlaying(!playing);
        if (!playing) {
            playAudioFromIndex();
        }
    };

    const translateTo = () => {
        const entry = translates[index];
        console.log('entry : ' + JSON.stringify(entry));
        setTranslates(prevMap => ({
            ...prevMap,
            [index]: {
                ...prevMap[index], // Copier les valeurs existantes pour ne pas les perdre
                exists: !entry.exists // Mettre à jour la valeur2 avec la nouvelle valeur
            }
        }));
    }

    const traductionTo = () => {
        setTraduction(!traduction);
    }

    useEffect(() => {
        setPlaying(isPlaying);
    }, [isPlaying]);

    if (phrase && phrase.length > 0) {
        content = <td style={{width: '90%'}}>{sentence}<span style={{color: 'green', display: traduction ? 'block' : 'none'}}
                                                             dir={isRtlUser ? 'rtl' : 'ltr'}>{phrase}</span></td>;
        bouton = <TranslateIcon onClick={traductionTo}/>;
    } else {
        content = <td style={{width: '90%'}}>{sentence}<span style={{color: 'green'}}
                                                             dir={isRtlUser ? 'rtl' : 'ltr'}>{translates[index]?.exists == true ?
            <br/> : null}{translates[index]?.exists == true ? translates[index]?.sentence : null}</span></td>;
        bouton = <TranslateIcon style={{display: Object.keys(translates).length > 1 ? 'block' : 'none'}}
                                onClick={translateTo}/>;
    }

    return (
        <table style={{width: '100%', direction: isRtlTo ? 'rtl' : 'ltr'}}>
            <tr style={{backgroundColor: playing ? '#dfe9ef' : index % 2 === 0 ? '#ebf3fd' : '#fff'}}>
                {content}
                <td
                    style={{width: '5%'}}>
                    <PlayCircleIcon onClick={handlePlayPause}
                                    style={{display: playing ? 'none' : 'block'}}/>
                    <PauseCircleIcon
                        onClick={handlePlayPause} style={{display: playing ? 'block' : 'none'}}/>
                </td>
                <td style={{width: '5%'}}>
                    {bouton}
                </td>
            </tr>
            <tr style={{backgroundColor: playing ? '#dfe9ef' : index % 2 === 0 ? '#ebf3fd' : '#fff'}}>
                <td
                    colSpan={2}>
                <ReactPlayer
                        ref={playerRef}
                        url={url}
                        playing={playing}
                        controls={false}
                        width='0'
                        height='0'
                        onEnded={onEnd}
                    />
                </td>
            </tr>
        </table>
    );
};

export default AudioPlayer;
