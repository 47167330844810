import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export let backendUrl = '';
if(process.env.REACT_APP_API_URL){
    backendUrl = process.env.REACT_APP_API_URL;
}
export let env = '';
if(process.env.REACT_APP_ENV){
    env = process.env.REACT_APP_ENV;
}
// Configurez l'instance Axios
const instance = axios.create({
    baseURL: backendUrl,
});

interface AxiosRequestConfigExtended extends AxiosRequestConfig {
    _retry?: boolean;
}


// Fonction de rafraîchissement du token
const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) throw new Error("No refresh token found.");

        let url = 'oauth2/v2/auth';
        if (env === 'dev') {
            url += '/dev';
        }
        if (env === 'help') {
            url += '/help';
        }
        const response = await instance.post(url, {'redirect_url': ''}, {
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
            params: {
                type: 'refresh'
            }
        });
        const tokens = response.data;
        localStorage.setItem('token', tokens.id_token);
        localStorage.setItem('access_token', tokens.access_token);
        localStorage.setItem('token_expiry', tokens.token_expiry);
        localStorage.setItem('current_time', tokens.current_time);
    } catch (error) {
        console.error("Error refreshing access token:", error);
    }
};

// Ajoutez un intercepteur de réponse
instance.interceptors.response.use((response: AxiosResponse) => {
    // Si la réponse est OK, retournez la réponse directement
    return response;
}, async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfigExtended;

    // Si une erreur 401 est reçue, essayez de rafraîchir le token
    if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {

        originalRequest._retry = true;
        await refreshAccessToken();
        const token = localStorage.getItem('token');
        // Assurez-vous que les en-têtes sont définis
        originalRequest.headers = originalRequest.headers || {};
        // Remplacer le token dans l'en-tête de la demande originale
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        // Renvoyer la demande originale
        return instance(originalRequest);
    }
    // Si l'erreur n'est pas une 401 ou si le rafraîchissement échoue, rejetez l'erreur.
    return Promise.reject(error);
});

export default instance;

