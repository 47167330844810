import React, {useContext, useEffect, useState} from "react";
import jwtDecode from 'jwt-decode';
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {Button, Card, CardContainer, FooterItem, FooterLabel} from './CardStyles';
import {CircularProgress} from "@mui/material";
import useWebSocket from "react-use-websocket";
import i18n from "./i18n";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import { ReactComponent as CopyIcon } from './icons/content_copy_24px.svg';
import CopyToClipboard from "react-copy-to-clipboard";

import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';

const StoryContainer = styled.div`
    padding-bottom: 50px;  // Ajoutez ceci
    display: block;
    flex-direction: column;  
    align-items: center; 
    justify-content: center;
    width: 100%;  

    @media (max-width: 600px) {
        align-items: center;
    }

    @media (min-width: 1024px) { 
        align-items: center;
    }
`;

const StoryPrompt = styled.span`
    font-size: 17px;
    color: #4a148c;
    padding-bottom: 10px;
    font-family: Space Grotesk,sans-serif;

    @media (max-width: 600px) {
        margin-bottom: 10px;
        font-size: 14px; // Reduce font-size for mobile
    }
`;

const StoryTextArea = styled.textarea`
    flex-grow: 1; 
    margin: 10px 10px;  
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #4a148c;
    font-family: Space Grotesk,sans-serif;
    width: 80%;
    min-height: 200px;

    @media (max-width: 600px) {
        width: 90%;
        margin: 0 0 10px 5px;
        font-size: 16px; // Reduce font-size for mobile
    }
`;

const ResultContainer = styled.div`
    margin-top: 5px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
    border-radius: 15px;
    padding: 10px;
    margin-top: 5px;
    max-width: 540px;
`;

const ResultHeading = styled.h2`
    font-size: 20px;
    color: #4a148c;  // purple
    font-family: Space Grotesk,sans-serif;
`;

const LoadingContainerWait = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #dde5f9;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
`;

const LoadingText = styled.p`
  font-family: Space Grotesk,sans-serif;
  font-size: 14px;
  color: #151516;
  text-align: center;
  margin-top: 5px;
`;

type TextToMediaProps = {
    mediaType: 'audio' | 'video' | 'text';
    kind: 'answers' | 'explanations' | 'summary' | 'translation' | 'other' | null;
    langResponse: string;
}

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    response?: string;
};

const TextToMedia: React.FC<TextToMediaProps> = ({mediaType, kind, langResponse}) => {
    const [result, setResult] = useState<MediaResult | null>(null);
    const [textParts, setTextParts] = useState<string>('');
    const [text, setText] = useState<string>("");
    const { shakeButton } = useContext(ShakeButtonContext);

    const [loadingResult, setLoadingResult] = useState(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);

    const { t } = useTranslation();

    const token = localStorage.getItem('token');
    let errorCountString = localStorage.getItem('errorCountTextToMedia');
    let errorCount = errorCountString ? parseInt(errorCountString) : 0;
    const [errorMessage, setErrorMessage] = useState<boolean>(false);


    const {
        sendMessage,
        lastMessage,
    } = useWebSocket(`wss://wss.assist.studio/?token=${token}`, {
        onOpen: () => {
            console.log('opened');
            errorCount = 0;
            localStorage.removeItem('errorCountTextToMedia');
            setErrorMessage(false);
        },
        shouldReconnect: (closeEvent) => true,
        onClose: event => {
            setButtonDisable(true);
            setLoadingResult(false);
        },
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            errorCount++;
            if (errorCount >= 3) {
                setErrorMessage(true);
            } else {
                localStorage.setItem('errorCountTextToMedia', errorCount.toString());
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    });

    useEffect(() => {
        Prism.highlightAll();
    }, []);


    useEffect(() => {
        if (text) {
            setButtonDisable(false);
        }
    }, [text]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);
                if (!eventData.message) {
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                    setTextParts(eventData.response.replace(/&&&&&&/g, "<br /> <br />"));
                    setTextParts(eventData.response.replace(/&&&/g, "<br />"));
                    setLoadingResult(false);
                }

                if (eventData.message === "End") {
                    setButtonDisable(false);
                    setLoadingResult(false);
                }

                if (eventData.message === "Success") {
                    setButtonDisable(false);
                    setLoadingResult(false);
                }
                if (eventData.message === "creditExhaustedError" || eventData.message === "Internal server error") {
                    console.log('creditExhaustedError');
                    setButtonDisable(true);
                    setLoadingResult(false);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
            }
        }
    }, [lastMessage]);

    const submitText = async () => {

        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        const payload = {
            prerequirement: text,
            action: 'text-help',
            user_id: user_id,
        };

        sendMessage(JSON.stringify(payload));

        setLoadingResult(true);
        setButtonDisable(true);
    };

    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';

    const processText = (text: string) => {

        const codeParts = text.replace(/```(\w+)([\s\S]*?)```/gs, (match, lang, code) => {
            return `<pre class="language-${lang}"><code className="language-${lang}">${code}</code></pre>`;
        });

        const code = codeParts.replace(/`([\s\S]*?)`/gs, (match, p1) => {
            return `<code>${p1}</code>`;
        });

        const styledText = code.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
            return `<strong>${p1}</strong>`;
        });

        const textWithLineBreaks = styledText.replace(/(\n)/g, '<br />');

        return textWithLineBreaks;
    };

    return (
        <div>

            <StoryContainer>
                <span hidden={!errorMessage} style={{color: 'red'}}>{t('errorInternetConnection')}</span>
                <CardContainer>
                    <Card>
                        <StoryPrompt>{t('question', {kind: kind != null ? t(kind) : ''})}</StoryPrompt>
                        <StoryTextArea value={text} onChange={e => setText(e.target.value)}/>
                        <Button onClick={submitText} disabled={buttonDisable || errorMessage || text === ''}>{t('send')}</Button>
                        <Button onClick={e => setText('')} disabled={text === ''}>{t('clear')}</Button>
                    </Card>
                </CardContainer>

                {result && result.response && (
                    <ResultContainer>
                        <ResultHeading>{t('result')}:</ResultHeading>
                        <div style={{textAlign: 'right'}}>
                            {!loadingResult && (
                                <FooterItem>
                                    <CopyToClipboard
                                        text={textParts.replace(/<(?!br\s*\/)[^>]*>/g, '').replace(/<br\s*\/?>/g, '\n')}>
                                        <CopyIcon style={{
                                            marginRight: '10px',
                                            cursor: 'pointer',
                                            color: '#4a148c',
                                            maxHeight: '30px'
                                        }}></CopyIcon>
                                    </CopyToClipboard>
                                    <FooterLabel>{t('copy')}</FooterLabel>
                                </FooterItem>
                            )}
                        </div>
                        <MathJaxContext>
                            <MathJax dynamic>
                                <div
                                    dangerouslySetInnerHTML={{__html: processText(textParts)}}
                                    style={{direction: isRtl ? 'rtl' : 'ltr'}}/>
                            </MathJax>
                        </MathJaxContext>
                    </ResultContainer>
                )}

                {loadingResult &&
                    <LoadingContainerWait>
                        <CircularProgress/>
                        <LoadingText>
                            {kind != null ? t('kind', t(kind)) : ''}
                        </LoadingText>
                    </LoadingContainerWait>
                }

            </StoryContainer>
        </div>
    );
};

export default TextToMedia;
