import React, {useEffect, useState} from "react";
import TextToMedia from "./TextToMedia";
import ScanToMedia from "./ScanToMedia";
import styled from "@emotion/styled";
import {useTranslation} from 'react-i18next';
import i18n from "./i18n";
import UploadToMedia from "./UploadToMedia";

const Container = styled.div`
    display: flex;
    flex-direction: column;  
    align-items: center; 
    width: 100%;  
    flex-wrap: wrap;
    padding-bottom: 10px;  // Adjust as needed
    padding-top: 70px;
    background: #ffffff;

    @media (max-width: 600px) {
        align-items: center; 
        padding-top: 50px;
    }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #4a148c; // purple
    font-family: Space Grotesk,sans-serif;
    margin-top: 20px;
    margin-bottom: 10px;
`;

const Select = styled.select`
    margin-left: 4px;  
    font-size: 14px;
    padding: 5px;
    border-radius: 10px;  // rounded border
    border: 1px solid #000000; // purple border
    font-family: Space Grotesk,sans-serif;

    @media (max-width: 600px) {
        margin-left: 0;
        width: 100%; 
        font-size: 12px;
    }
`;

const Span = styled.span`
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 600px) {
        font-size: 12px;
        margin-left: 3px;
        margin-right: 3px;
    }
`;

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const;
type LanguageOption = typeof LANGUAGE_OPTIONS[number];

const languageCodeToName: Record<string, LanguageOption> = {
    'en': 'english',
    'es': 'spanish',
    'ar': 'arabic',
    'fr': 'french',
    'de': 'german',
    'zh': 'chinese',
    'ja': 'japanese',
    'hi': 'hindi',
    'tr': 'turkish',
    'ko': 'korean',
    'it': 'italian',
    'nl': 'dutch',
    'ru': 'russian',
    'id': 'indonesian',
};
const languageNameToCode: Record<LanguageOption, string> = {
    'english': 'en',
    'spanish': 'es',
    'arabic': 'ar',
    'french': 'fr',
    'german': 'de',
    'chinese': 'zh',
    'japanese': 'ja',
    'hindi': 'hi',
    'turkish': 'tr',
    'korean': 'ko',
    'italian': 'it',
    'dutch': 'nl',
    'russian': 'ru',
    'indonesian': 'id',
};

const ResultText = styled.p`
    font-size: 13px;
    color: #e5a02e;  // purple
    font-family: Space Grotesk,sans-serif;
    margin-top: 0px;
`;

type MediaCreatorProps = {
    mediaType: 'audio' | 'video' | 'text';
    sourceType: 'scan' | 'upload' | 'text';
}

const MediaCreator: React.FC<MediaCreatorProps> = ({mediaType, sourceType}) => {
    const [kind, setKind] = useState<'answers' | 'explanations' | 'summary' | 'translation' | 'other' | null>(null);
    const lang = localStorage.getItem('language');
    const initialLanguage = lang ? lang : 'en';
    const [language, setLanguage] = useState<LanguageOption>(languageCodeToName[initialLanguage]);
    const [languageCode, setLanguageCode] = useState<string>(initialLanguage);


    useEffect(() => {
        setKind(null); // réinitialiser sourceType à null chaque fois que le composant est monté
    }, [mediaType, sourceType]);
    const handleSourceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setKind(e.target.value as 'answers' | 'explanations' | 'summary' | 'translation' | 'other');
        if (lang) {
            setLanguageCode(lang)
            setLanguage(languageCodeToName[lang]);
        }
    };

    const handleLanguageChange = (selectedLanguage: LanguageOption) => {
        setLanguage(selectedLanguage);
        const lang = languageNameToCode[selectedLanguage];
        setLanguageCode(lang);
    };

    useEffect(() => {
        setLanguage(language);
        const lang = languageNameToCode[language];
        setLanguageCode(lang);
    }, [language]);

    useEffect(() => {
        ContentComponent = <UploadToMedia mediaType={mediaType} kind={kind} langResponse={languageCode}/>;
    }, [kind]);


    const {t} = useTranslation();
    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';

    let ContentComponent;
    switch (sourceType) {
        case 'scan':
            ContentComponent = <ScanToMedia mediaType={mediaType} kind={kind} langResponse={languageCode}/>;
            break;
        case 'upload':
            ContentComponent = <UploadToMedia mediaType={mediaType} kind={kind} langResponse={languageCode}/>;
            break;
        case 'text':
            ContentComponent = <TextToMedia mediaType={mediaType} kind={kind} langResponse={languageCode}/>;
            break;
    }

    return (
        <Container style={{direction: isRtl ? 'rtl' : 'ltr'}}>
            <Label>
                {sourceType && sourceType !== 'text' &&
                    <>
                        <Select value={kind || ''} onChange={handleSourceTypeChange}>
                            <option value="">--{t('chooseSourceType')}--</option>
                            <option value="answers">{t('answers')}</option>
                            <option value="explanations">{t('explanations')}</option>
                            <option value="summary">{t('summary')}</option>
                            <option value="translation">{t('translation')}</option>
                            <option value="other">{t('other')}</option>
                        </Select>
                    </>
                }

                { sourceType && sourceType !== 'text' &&
                    <>
                    <Span>{t('responseLang')}</Span>
                    <Select value={language} onChange={(e) => handleLanguageChange(e.target.value as LanguageOption)}>
                        <option value="">{t('languages')}</option>
                        {LANGUAGE_OPTIONS.map((lang) => (
                            <option key={lang} value={lang}>
                                {t(lang)}
                            </option>
                        ))}
                    </Select>
                    </>
                }
            </Label>

            {kind === 'answers' && (sourceType === 'scan' || sourceType === 'upload') && (
                <ResultText>
                    {t('resultDocument')}
                </ResultText>
            )}
            {kind === 'explanations' && (sourceType === 'scan' || sourceType === 'upload') && (
                <ResultText>
                    {t('understandDocument')}
                </ResultText>
            )}
            {kind === 'summary' && (sourceType === 'scan' || sourceType === 'upload') && (
                <ResultText>
                    {t('summaryDocument')}
                </ResultText>
            )}

            {ContentComponent}
        </Container>
    );
};

export default MediaCreator;
