import React, {useContext, useEffect, useState} from "react";
import jwtDecode from 'jwt-decode';
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {Card, CardContainer} from './CardStyles';
import useWebSocket from "react-use-websocket";
import i18n from "./i18n";

import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';
import DialogueListContainer from "./DialogueListContainer";
import {CircularProgress} from "@mui/material";
import {useList} from "./store/ListContext";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 70px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
        padding-top: 50px;
    }
`;

const ContainerL1 = styled.div`
    display: flex;
    flex-direction: row;  // Change this from column to row
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
    padding-bottom: 20px;  // Adjust as needed
    padding-top: 10px;
    @media (max-width: 600px) {
        padding-bottom: 20px;
        padding-top: 10px;
    }
`;

const StoryPrompt = styled.span`
    font-size: 14px;
    padding-bottom: 5px;
    font-family: Space Grotesk,sans-serif;

    @media (max-width: 600px) {
        margin-bottom: 5px;
    }
`;

const StoryTextArea = styled.textarea`
    flex-grow: 1;
    margin: 10px 10px;
    font-size: 14px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #4a148c;
    font-family: Space Grotesk,sans-serif;
    width: 80%;
    min-height: 200px;

    @media (max-width: 600px) {
        width: 90%;
        margin: 0 0 10px 5px;
    }
`;

const StoryText = styled.input`
    flex-grow: 1;
    margin: 5px 5px;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #4a148c;
    font-family: Space Grotesk,sans-serif;
    width: 20%;
    @media (max-width: 600px) {
        width: 20%;
        margin: 0 0 5px 5px;
        margin-bottom: 5px;
        margin-right: 3px;
    }
`;

const StoryButton = styled.button`
    font-size: 14px;
    padding: 10px 20px;
    background-color: #dde5f9;
    color: black;
    border: none;
    border-radius: 10px;
    font-family: Space Grotesk,sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
    border: 1px solid #4a148c;

    &:hover {
        background-color: #dde5f9;
    }

    @media (max-width: 600px) {
    }

    &:disabled {
        background-color: #757575;  // grey background when disabled
        cursor: not-allowed;  // change cursor when disabled
    }
`;

const Select = styled.select`
    margin-left: 4px;  
    font-size: 14px;
    padding: 5px;
    border-radius: 10px;  // rounded border
    border: 1px solid #000000; // purple border
    font-family: Space Grotesk,sans-serif;
    margin-right: 5px;

    @media (max-width: 600px) {
        width: 50%; 
        font-size: 12px;
        margin-bottom: 5px;
        
    }
`;

const LoadingContainerWait = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #dde5f9;
    border-radius: 20px;
    padding: 10px;
    margin: 0 auto;
`;

const LoadingText = styled.p`
    font-family: Space Grotesk,sans-serif;
    font-size: 14px;
    color: #151516;
    text-align: center;
    margin-top: 5px;
`;

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const;
type LanguageOption = typeof LANGUAGE_OPTIONS[number];

const languageCodeToName: Record<string, LanguageOption> = {
    'en': 'english',
    'es': 'spanish',
    'ar': 'arabic',
    'fr': 'french',
    'de': 'german',
    'zh': 'chinese',
    'ja': 'japanese',
    'hi': 'hindi',
    'tr': 'turkish',
    'ko': 'korean',
    'it': 'italian',
    'nl': 'dutch',
    'ru': 'russian',
    'id': 'indonesian',
};
const languageNameToCode: Record<LanguageOption, string> = {
    'english': 'en',
    'spanish': 'es',
    'arabic': 'ar',
    'french': 'fr',
    'german': 'de',
    'chinese': 'zh',
    'japanese': 'ja',
    'hindi': 'hi',
    'turkish': 'tr',
    'korean': 'ko',
    'italian': 'it',
    'dutch': 'nl',
    'russian': 'ru',
    'indonesian': 'id',
};

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    audio_url: string;
    sentence: string;
    phrase:string;
};
const TextToMedia = () => {
    const [result, setResult] = useState<MediaResult | null>(null);
    const [dialogue, setDialogue] = useState<(MediaResult)[]>([]);
    const [text, setText] = useState<string>("");
    const [text1, setText1] = useState<string>("");
    const [text2, setText2] = useState<string>("");
    const { shakeButton } = useContext(ShakeButtonContext);

    const [loadingResult, setLoadingResult] = useState(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);

    const { t } = useTranslation();

    const token = localStorage.getItem('token');

    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';
    const initialLanguage = 'en';
    const [language, setLanguage] = useState<LanguageOption>(languageCodeToName[initialLanguage]);
    const [languageCode, setLanguageCode] = useState<string>(initialLanguage);
    const { setList } = useList();

    let errorCountString = localStorage.getItem('errorCountDialogue');
    let errorCount = errorCountString ? parseInt(errorCountString) : 0;
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const {
        sendMessage,
        lastMessage,
    } = useWebSocket(`wss://wss.assist.studio/?token=${token}`, {
        onOpen: () => {
            console.log('opened');
            errorCount = 0;
            localStorage.removeItem('errorCountDialogue');
            setErrorMessage(false);
        },
        shouldReconnect: (closeEvent) => true,
        onClose: event => {
            setButtonDisable(true);
            setLoadingResult(false);
        },
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            errorCount++;
            if (errorCount >= 3) {
                setErrorMessage(true);
            } else {
                localStorage.setItem('errorCountDialogue', errorCount.toString());
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    });

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    useEffect(() => {
        if (result) {
            setResult(result);
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [result]);

    useEffect(() => {
        if (text || (text1 && text2)) {
            setButtonDisable(false);
        }
    }, [text, text1, text2]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);

                if (!eventData.message && !eventData.phrases) {
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                    if(result) {
                        setDialogue(prevDialogue => [...prevDialogue, result]);
                    }
                    setLoadingResult(false);
                }

                if (eventData.phrases) {
                    setList(eventData.phrases);
                }

                if (eventData.message === "Success") {
                    setButtonDisable(false);
                    setLoadingResult(false);
                }
                if (eventData.message === "creditExhaustedError" || eventData.message === "Internal server error") {
                    console.log('creditExhaustedError');
                    setButtonDisable(false);
                    setLoadingResult(false);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
                setButtonDisable(false);
                setLoadingResult(false);
            }
        }
    }, [lastMessage]);

    const submitText = async () => {
        setResult(null);
        setDialogue([]);
        setList([]);

        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        const payload = {
            subject: text,
            language: languageCode,
            my_language: languageCodeToName[currentLanguage],
            action: 'dialogue',
            user_id: user_id,
        };

        sendMessage(JSON.stringify(payload));

        setLoadingResult(true);
        setButtonDisable(false);
    };

    const handleLanguageChange = (selectedLanguage: LanguageOption) => {
        setLanguage(selectedLanguage);
        const lang = languageNameToCode[selectedLanguage];
        setLanguageCode(lang);
        console.log(languageCode);
    };

    return (
        <div>
            <Container style={{direction: isRtl ? 'rtl' : 'ltr'}}>`
                <span hidden={!errorMessage} style={{color: 'red'}}>{t('errorInternetConnection')}</span>
                <ContainerL1>
                    <StoryPrompt>{t('conversationIn')}</StoryPrompt>
                    <Select value={language} onChange={(e) => handleLanguageChange(e.target.value as LanguageOption)}>
                        <option value="">{t('languages')}</option>
                        {LANGUAGE_OPTIONS.map((lang) => (
                            <option key={lang} value={lang}>
                                {t(lang)}
                            </option>
                        ))}
                    </Select>
                </ContainerL1>
                <StoryPrompt>{t('subject')}</StoryPrompt>
                <StoryTextArea value={text} onChange={e => setText(e.target.value)}/>
                <StoryButton onClick={submitText} disabled={buttonDisable || errorMessage}>{t('send')}</StoryButton>

                {loadingResult && (
                    <CircularProgress disableShrink={true}/>
                )}
                {dialogue.length > 0 && (
                    <CardContainer>
                        <Card>
                            <DialogueListContainer dialogue={dialogue} lang={languageCode} language={language}/>
                        </Card>
                    </CardContainer>
                )}

            </Container>
        </div>
    );
};

export default TextToMedia;
