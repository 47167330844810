import React, {ButtonHTMLAttributes, useContext, useEffect, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from "jwt-decode";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {
    AudioPlayerContainer,
    AudioPlayerWrapper,
    Button,
    Card,
    CardContainer,
    StyledAudioReactPlayer
} from './CardStyles';
import MicIcon from '@mui/icons-material/Mic';
import {CircularProgress} from "@mui/material";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import i18n from "./i18n";
import useWebSocket from "react-use-websocket";

const Container = styled.div`
    display: flex;
    flex-direction: column;  
    align-items: flex-start; 
    width: 100%;  
    flex-wrap: wrap;
    const MainContainer = styled.div\`
    padding-bottom: 10px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        align-items: center; 
        padding-top: 50px;
    }
`;
const ButtonsContainer = styled.div`
    display: contents;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
`;

const ResultContainer = styled.div`
    margin-top: 5px;
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    recording: boolean;
}
const BlinkingMicIcon = styled(MicIcon)`
  animation: blinkingEffect 1s infinite;
  font-size: 30px;  // font size of the mic icon
  color: white;  // initial color of the mic icon

  @keyframes blinkingEffect {
    0% { color: red; }
    50% { color: transparent; }
    100% { color: red; }
  }
`;

const StyledButton = styled.button<ButtonProps>`
  display: ${props => props.recording ? 'flex' : 'none'};
    align-self: center;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 7px 7px;
    background: #dde5f9;  // purple background
    color: black;  // white text
    border-radius: 7px;  // rounded border
    font-family: Space Grotesk,sans-serif;
    cursor: pointer;  // change cursor on hover
    transition: background-color 0.3s;  // smooth color transition
    margin-right: 10px;
    border: 1px solid #4a148c;
    // change background color on hover
    &:hover {
        background-color: #dde5f9;  // darker purple
    }

    &:last-child {
        margin-bottom: 0;  // remove margin from the last button
    }

    @media (min-width: 600px) {
        margin-right: 10px;
        margin-bottom: 0;
    }

    &:disabled {
        background-color: #dedada;  // grey background when disabled
        border-color: #dedada;
        cursor: not-allowed;  // change cursor when disabled
    }
`;

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    audio_url?: string;
    response?: string;
    requirement?: string;
};

const VoiceConversation = () => {
    const [key, setKey] = useState<string | null>(null);
    const [requirement, setRequirement] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [result, setResult] = useState<MediaResult | null>(null);
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const {shakeButton} = useContext(ShakeButtonContext);

    const [buttonDisable, setButtonDisable] = useState<boolean>(false);
    const [noCredit, setNoCredit] = useState<boolean>(true);

    const [history, setHistory] = useState<(MediaResult | null)[]>([]);
    const [ind, setInd] = useState<number>(-1);

    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';

    const token = localStorage.getItem('token');
    let errorCountString = localStorage.getItem('errorCountVoiceConversation');
    let errorCount = errorCountString ? parseInt(errorCountString) : 0;
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const {
        sendMessage,
        lastMessage,
    } = useWebSocket(`wss://wss.assist.studio/?token=${token}`, {
        onOpen: () => {
            console.log('opened');
            errorCount = 0;
            localStorage.removeItem('errorCountVoiceConversation');
            setErrorMessage(false);
        },
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
        onClose: event => {
        },
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            errorCount++;
            if (errorCount >= 3) {
                setErrorMessage(true);
            } else {
                localStorage.setItem('errorCountVoiceConversation', errorCount.toString());
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    });

    useEffect(() => {
        // Cette fonction sera exécutée chaque fois que lastMessage change
        if (lastMessage) {
            // Faites d'autres opérations en fonction du contenu du message reçu
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);
                if (!eventData.message) {
                    const responseOriginal: string | undefined = eventData.response;
                    let responseModifiee = responseOriginal?.replace(/&&&&&&/g, "<br /> <br />");
                    responseModifiee = responseModifiee?.replace(/&&&/g, "<br />") || '';
                    eventData.response = responseModifiee;
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                }
                setInd(-1);
                if (eventData.audio_url) {
                    setButtonDisable(false);
                    if (history.length > 0 && history[history.length - 1] !== null) {
                        setInd(history.length - 1);
                    }
                }
                if (eventData.message === "Success") {
                    setHistory(prevHistory => [...prevHistory, result]);
                    setButtonDisable(false);
                }
                if (eventData.message === "creditExhaustedError") {
                    console.log('creditExhaustedError');
                    // setButtonDisable(true);
                }
                if (eventData.message === "Endpoint request timed out") {
                    console.log('error');
                    // setButtonDisable(true);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
            }
        }
    }, [lastMessage]);

    const startRecording = () => {
        let mimeType: string;
        if (navigator.userAgent.search("Chrome") >= 0) {
            mimeType = 'audio/webm';
            console.log('Chrome');
        } else if (navigator.userAgent.search("Firefox") >= 0) {
            mimeType = 'audio/ogg'; // ou 'audio/wave'
            console.log('Firefox');
        } else {
            // fallback pour autres navigateurs, à ajuster en fonction de vos besoins
            mimeType = 'audio/webm';
            console.log('autres');
        }
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(stream => {
                const newMediaRecorder = new MediaRecorder(stream, {mimeType});
                newMediaRecorder.start();

                newMediaRecorder.onstop = () => {
                    stream.getTracks().forEach(track => track.stop());
                };

                setMediaRecorder(newMediaRecorder);
                setRecording(true);
            })
            .catch(error => console.error('Error accessing media devices.', error));
    };

    const stopRecording = () => {
        //setResult(null);
        if (mediaRecorder) {
            mediaRecorder.ondataavailable = event => {
                const audioBlob = event.data;
                const formData = new FormData();
                formData.append('audio', audioBlob, 'audio.webm');

                const token = localStorage.getItem('token');
                if (token === null) {
                    console.error("No token found in localStorage.");
                    return;
                }
                const decodedToken: JWTDeCode = jwtDecode(token);
                const user_id = decodedToken.sub;

                axiosInstance.post( '/chat-audio-gate/' + user_id, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        key: key
                    },
                })
                    .then(response => {
                        console.log('response.data ' + JSON.stringify(response.data));
                        setRequirement(response.data.transcription);
                        setKey(response.data.key);

                        let prompts: string[] = [];
                        let responses: string[] = [];
                        if (history) {
                            prompts = history.map(item => item!.requirement).filter(Boolean) as string[];
                            responses =  history.map(item => item!.response).filter(Boolean) as string[];
                        } else {
                            prompts = [];
                            responses = [];
                        }

                        const payload = {
                            requirement: response.data.transcription,
                            prompts: prompts,
                            responses: responses,
                            action: 'chat-audio',
                            user_id: user_id,
                            key: response.data.key,
                        };

                        sendMessage(JSON.stringify(payload));

                    })
                    .catch(error => {
                        console.error('Error sending audio to backend', error);
                        // Set loadingAudios to false when got the result
                        setButtonDisable(true);
                        setNoCredit(false);
                        return;
                    });
            };
            mediaRecorder.stop();
            setRecording(false);
            setButtonDisable(true);
            //setLoadingResult(true);
        }
    };

    useEffect(() => {
        if (result) {
            setResult(result);
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [result]);

    const processText = (text: string | undefined) => {

        if (!text) {
            return '';
        }

        const codeParts = text.replace(/```(\w+)([\s\S]*?)```/gs, (match, lang, code) => {
            return `<pre class="language-${lang}" style="width:80%;font-size:xx-small;"><code className="language-${lang}">${code}</code></pre>`;
        });

        const code = codeParts.replace(/`([\s\S]*?)`/gs, (match, p1) => {
            return `<code>${p1}</code>`;
        });

        const styledText = code.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
            return `<strong>${p1}</strong>`;
        });

        const textWithLineBreaks = styledText.replace(/(\n)/g, '<br />');

        return "<strong>A: </strong>" + textWithLineBreaks;
    };

    return (
        <>
            <Container>
                <span hidden={!errorMessage} style={{color: 'red'}}>{t('errorInternetConnection')}</span>
                {/* Afficher l'historique des prompts et responses */}
                {history && history.slice(0, ind).map((hi, index) => (
                    <ResultContainer>
                        <CardContainer>
                            <Card>
                                <div><strong>Q:</strong> {hi!.requirement}</div>
                                <MathJaxContext>
                                    <MathJax dynamic>
                                        <div
                                            dangerouslySetInnerHTML={{__html: processText(hi!.response)}}
                                            style={{direction: isRtl ? 'rtl' : 'ltr'}}/>
                                    </MathJax>
                                </MathJaxContext>
                                <AudioPlayerContainer>
                                    <AudioPlayerWrapper>
                                        <StyledAudioReactPlayer
                                            url={hi!.audio_url}
                                            controls={true}
                                            playing={false}
                                        />
                                    </AudioPlayerWrapper>
                                </AudioPlayerContainer>
                            </Card>
                        </CardContainer>
                    </ResultContainer>
                ))}
                {
                    result && (
                        <ResultContainer>
                            <CardContainer>
                                <Card>
                                    <div><strong>Q:</strong> {result.requirement}</div>
                                    <MathJaxContext>
                                        <MathJax dynamic>
                                            <div
                                                dangerouslySetInnerHTML={{__html: processText(result.response)}}
                                                style={{direction: isRtl ? 'rtl' : 'ltr'}}/>
                                        </MathJax>
                                    </MathJaxContext>
                                    <AudioPlayerContainer>
                                        <AudioPlayerWrapper>
                                            <StyledAudioReactPlayer
                                                url={result!.audio_url}
                                                controls={true}
                                                playing={true}
                                            />
                                        </AudioPlayerWrapper>
                                    </AudioPlayerContainer>
                                </Card>
                            </CardContainer>
                        </ResultContainer>
                    )}
                {(buttonDisable && !errorMessage) && (
                    <CircularProgress disableShrink={true}/>
                )}
                <ButtonsContainer>
                    <Button onClick={startRecording} hidden={recording} disabled={buttonDisable || errorMessage}><MicIcon/></Button>
                    <StyledButton onClick={stopRecording} recording={recording}>
                        <BlinkingMicIcon/>
                    </StyledButton>
                </ButtonsContainer>
            </Container>
        </>
    );
}

export default VoiceConversation;
