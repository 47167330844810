import React, {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {Button, CardContainer, StoryInput} from './CardStyles';
import useWebSocket from "react-use-websocket";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import i18n from "./i18n";


export const Card = styled.div`
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: 600px) {
        width: 80vw;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;  
    align-items: flex-start; 
    width: 100%;  
    flex-wrap: wrap;
    padding-bottom: 70px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
        padding-top: 50px;
    }
`;

const ResultContainer = styled.div`
    margin-top: 5px;
`;

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    response?: string;
    requirement?: string;
    key?: string;
};

const TextConversation = () => {
    const [result, setResult] = useState<MediaResult | null>(null);
    const [history, setHistory] = useState<(MediaResult | null)[]>([]);

    const [buttonDisable, setButtonDisable] = useState<boolean>(false);

    const [text, setText] = useState<string>("");

    const {t} = useTranslation();
    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';

    const token = localStorage.getItem('token');
    let errorCountString = localStorage.getItem('errorCountTextConversation');
    let errorCount = errorCountString ? parseInt(errorCountString) : 0;
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const {
        sendMessage,
        lastMessage
    } = useWebSocket(`wss://wss.assist.studio/?token=${token}`, {
        onOpen: () => {
            console.log('opened');
            errorCount = 0;
            localStorage.removeItem('errorCountTextConversation');
            setErrorMessage(false);
        },
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            errorCount++;
            if (errorCount >= 3) {
                setErrorMessage(true);
            } else {
                localStorage.setItem('errorCountTextConversation', errorCount.toString());
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    });

    useEffect(() => {
        // Cette fonction sera exécutée chaque fois que lastMessage change
        if (lastMessage) {
            // Faites d'autres opérations en fonction du contenu du message reçu
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);
                if (!eventData.message) {
                    const responseOriginal: string | undefined = eventData.response;
                    let responseModifiee = responseOriginal?.replace(/&&&&&&/g, "<br /> <br />");
                    responseModifiee = responseModifiee?.replace(/&&&/g, "<br />") || '';
                    eventData.response = responseModifiee;
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                }
                if (result && eventData.message === "key") {
                    result.key = eventData.key;
                    setButtonDisable(false);
                    setText('');
                }

                if (eventData.message === "Success") {
                    setHistory(prevHistory => [...prevHistory, result]);
                    setButtonDisable(false);
                }
                if (eventData.message === "creditExhaustedError") {
                    console.log('creditExhaustedError');
                    // setButtonDisable(true);
                }
                if (eventData.message === "Endpoint request timed out") {
                    console.log('error');
                    // setButtonDisable(true);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
            }
        }
    }, [lastMessage]);

    const send = () => {
        console.log('send:');
        //setResult(null);
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            return;
        }
        const decodedToken: JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        let prompts: string[] = [];
        let responses: string[] = [];
        if (history) {
            prompts = history.map(item => item!.requirement).filter(Boolean) as string[];
            responses =  history.map(item => item!.response).filter(Boolean) as string[];
        } else {
            prompts = [];
            responses = [];
        }

        const payload = {
            requirement: text,
            prompts: prompts,
            responses: responses,
            action: 'text',
            user_id: user_id,
            key: result?.key,
        };

        sendMessage(JSON.stringify(payload));

        setButtonDisable(true);
        setText('');
        setResult({});
    };

    useEffect(() => {
        if (result) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [result]);

    const processText = (text: string | undefined) => {

        if (!text) {
            return '';
        }

        const codeParts = text.replace(/```(\w+)([\s\S]*?)```/gs, (match, lang, code) => {
            return `<pre class="language-${lang}"><code className="language-${lang}">${code}</code></pre>`;
        });

        const code = codeParts.replace(/`([\s\S]*?)`/gs, (match, p1) => {
            return `<code>${p1}</code>`;
        });

        const styledText = code.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
            return `<strong>${p1}</strong>`;
        });

        const textWithLineBreaks = styledText.replace(/(\n)/g, '<br />');

        return "<strong>A: </strong>" + textWithLineBreaks;
    };


    return (
        <>
            <Container>
                <span hidden={!errorMessage} style={{color: 'red'}}>{t('errorInternetConnection')}</span>
                {/* Afficher l'historique des prompts et responses */}
                {history && history.slice(0, -1).map((hi, index) => (
                    <ResultContainer>
                        <CardContainer>
                            <Card>

                                <div><strong>Q:</strong> {hi!.requirement}</div>
                                <MathJaxContext>
                                    <MathJax dynamic>
                                        <div
                                            dangerouslySetInnerHTML={{__html: processText(hi!.response)}}
                                            style={{direction: isRtl ? 'rtl' : 'ltr'}}/>
                                    </MathJax>
                                </MathJaxContext>
                            </Card>
                        </CardContainer>
                    </ResultContainer>
                ))}
                {result && (
                    <ResultContainer>
                        <CardContainer>
                            <Card>

                                <div><strong>Q:</strong> {result.requirement}</div>
                                <MathJaxContext>
                                    <MathJax dynamic>
                                        <div
                                            dangerouslySetInnerHTML={{__html: processText(result.response)}}
                                            style={{direction: isRtl ? 'rtl' : 'ltr'}}/>
                                    </MathJax>
                                </MathJaxContext>
                            </Card>
                        </CardContainer>
                    </ResultContainer>
                )}
                <StoryInput value={text} onChange={e => setText(e.target.value)}/>
                <Button onClick={send} disabled={buttonDisable || errorMessage}>{t('send')}</Button>
            </Container>
        </>
    );
}

export default TextConversation;
