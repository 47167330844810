import React from 'react';
import {useMediaQuery} from "@mui/material";

interface YouTubeProps {
    videoId: string;
}

const YouTubeEmbed: React.FC<YouTubeProps> = ({ videoId }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div>
            <iframe
                width={isMobile ? '100%' : '550'}
                height="315"
                src={embedUrl}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default YouTubeEmbed;
