import React, {SyntheticEvent, useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from "jwt-decode";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {CircularProgress, IconButton, Snackbar, Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useWebSocket from "react-use-websocket";
import CopyToClipboard from 'react-copy-to-clipboard';
import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';


import { ReactComponent as DownloadIcon } from './icons/download_24px.svg';
import { ReactComponent as UploadIcon } from './icons/upload_24px.svg';
import { ReactComponent as InfoIcon } from './icons/info_24px.svg';
import { ReactComponent as RepeatIcon } from './icons/repeat_24px.svg';
import { ReactComponent as CopyIcon } from './icons/content_copy_24px.svg';

import {
    Button,
    ButtonsContainer, ErrorMessage, FooterItem, FooterLabel,
    InputText,
    InputTextContainer,
    ResultContainer, ResultHeading, SatisfiedMessage
} from "./CardStyles";
import {MathJaxContext, MathJax} from "better-react-mathjax";

const LoadingContainerWait = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #dde5f9;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
`;

const LoadingText = styled.p`
  font-family: Space Grotesk,sans-serif;
  font-size: 14px;
  color: #151516;
  text-align: center;
  margin-top: 5px;
`;

type UploadToMediaProps = {
    mediaType: 'audio' | 'video' | 'text';
    kind: 'answers' | 'explanations' | 'summary' | 'translation' | 'other' | null;
    langResponse: string;
}
type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    title: string;
    response?: string;
};
type RepeatData = {
    prerequirement: string;
    key_origin: string;
    keys_images: string[];
    sort_key: string;
    user_id: string;
};

const IMAGE_URI = '/help-content-doc/';
const UPLOAD_IMAGE_URI = '/help-upload-image/';


const generateInitialRefs = (length: number) =>
    Array.from({ length }, () => React.createRef<HTMLInputElement | null>());

const UploadToMedia: React.FC<UploadToMediaProps> = ({mediaType, kind, langResponse}) => {
    const [result, setResult] = useState<MediaResult | null>(null);
    const [textParts, setTextParts] = useState<string>('');
    const [recording, setRecording] = useState(false);
    const [language, setLanguage] = useState(langResponse);
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const { shakeButton } = useContext(ShakeButtonContext);
    const [loadingResult, setLoadingResult] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [otherRequest, setOtherRequest] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [repeatSection, setRepeatSection] = useState<boolean>(false);
    const [repeatData, setRepeatData] = useState<RepeatData | null>(null);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [checkedValues, setCheckedValues] = useState<boolean[]>([]);
    const [firstTime, setFirstTime] = useState(true);

    const [imageInputRefs, setImageInputRefs] = React.useState<React.RefObject<HTMLInputElement | null>[]>([]);
    const [open, setOpen] = React.useState(false);

    const [showRetryIndication, setShowRetryIndication] = useState(false);

    const mathJaxRef = useRef(null);

    const { t } = useTranslation();

    const token = localStorage.getItem('token');
    let errorCountString = localStorage.getItem('errorCountUploadToMedia');
    let errorCount = errorCountString ? parseInt(errorCountString) : 0;
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    const {
        sendMessage,
        lastMessage,
    } = useWebSocket(`wss://wss.assist.studio/?token=${token}`, {
        onOpen: () => {
            console.log('opened');
            errorCount = 0;
            localStorage.removeItem('errorCountUploadToMedia');
            setErrorMessage(false);
        },
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            errorCount++;
            if (errorCount >= 3) {
                setErrorMessage(true);
            } else {
                localStorage.setItem('errorCountUploadToMedia', errorCount.toString());
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
    });

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    useEffect(() => {
        // Mettez à jour les références initiales chaque fois que imageUrls change
        setImageInputRefs(generateInitialRefs(imageUrls.length));
    }, [imageUrls]);


    useEffect(() => {
        setLanguage(langResponse);
        setResult(null);
        setRepeatData(null);
        setRepeatSection(false);
        setTextParts('');
        setButtonDisableFunc();
        setImageUrls([]);
    }, [langResponse]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);
                if (!eventData.message) {
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                    setTextParts(eventData.response.replace(/&&&&&&/g, "<br /> <br />"));
                    setTextParts(eventData.response.replace(/&&&/g, "<br />"));
                    setLoadingResult(false);
                }
                if (eventData.message === "Images") {
                    setImageUrls(eventData.images_urls);
                    if (firstTime) {
                        setCheckedValues(new Array(eventData.images_urls.length).fill(true));
                        // Vous mettez à jour firstTime à false pour ne pas répéter cette opération
                        setFirstTime(false);
                    }
                    // Sinon, vous utilisez les valeurs retournées par le backend
                    else {
                        setCheckedValues(eventData.checked_values);
                    }
                }

                if (eventData.message === "End") {
                    setButtonDisable(false);
                    setLoadingResult(false);
                }

                if (eventData.message === "Success") {
                    setButtonDisable(false);
                    setLoadingResult(false);
                }
                if (eventData.message === "creditExhaustedError" || eventData.message === "Internal server error") {
                    console.log('Error');
                    setButtonDisable(false);
                    setLoadingResult(false);
                    setImageUrls([]);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
                setButtonDisable(false);
                setLoadingResult(false);
                setImageUrls([]);
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        setButtonDisableFunc();
    }, [kind]);

    const setButtonDisableFunc = () => {
        if (langResponse && kind && ((kind === 'other' && otherRequest) || (kind !== 'other'))) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
        setError('');
        setRepeatData(null);
        setRepeatSection(false);
        setImageUrls([]);
    };

    useEffect(() => {
        if (langResponse && kind && ((kind === 'other' && otherRequest) || (kind !== 'other'))) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
        setError('');
    }, [otherRequest]);

    useEffect(() => {
        setRepeatData(repeatData);
    }, [repeatData]);

    const handleOtherRequestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtherRequest(e.target.value);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setError('');
        setResult(null);
        setRepeatData(null);
        setRepeatSection(false);
        setTextParts('');
        setButtonDisableFunc();
        setImageUrls([]);
        if (files && files.length > 0) {
            const file = files[0];
            const formData = new FormData();
            const fileName = file.name;
            formData.append('file', file, fileName);

            const token = localStorage.getItem('token');
            if (token === null) {
                console.error("No token found in localStorage.");
                shakeButton();
                return;
            }

            const decodedToken: JWTDeCode = jwtDecode(token);
            const user_id = decodedToken.sub;
            axiosInstance.post(IMAGE_URI + user_id, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    fileType: mediaType,
                    kind: kind,
                    defaultLang: language,
                    fileName: fileName,
                    otherRequest: otherRequest
                },
            })
                .then(response => {
                    const data = response.data;
                    setResult(data);
                    // image_key, texts, lang, texts_tr, prerequirement
                    const payload = {
                        prerequirement: data.prerequirement,
                        key_origin: data.key_origin,
                        keys_images: data.keys_images,
                        sort_key: data.sort_key,
                        action: 'text-help',
                        user_id: user_id,
                    };

                    setRepeatData(data);

                    sendMessage(JSON.stringify(payload));
                })
                .catch(error => {
                    console.error('Error sending image to backend', error.response?.data?.error);
                    if (error.response && error.response.data) {
                        setError(error.response.data.error);
                    }
                    // Set loadingAudios to false when got the result
                    setLoadingResult(false);
                    setButtonDisable(false);
                    event.target.value = '';
                    return;
                });
        } else {
            console.error('Error: Unable to create blob from canvas');
        }
        setLanguage(langResponse);
        setRecording(false);
        setLoadingResult(true);
        setButtonDisable(true);
        setError('');
        event.target.value = '';
    };

    const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, keyImage:string | null, index:number) => {
        const images = event.target.files;
        if (images && images.length > 0) {
            const image = images[0];
            const formData = new FormData();
            const imageName = image.name;
            formData.append('file', image, imageName);

            const token = localStorage.getItem('token');
            if (token === null) {
                console.error("No token found in localStorage.");
                shakeButton();
                return;
            }

            const decodedToken: JWTDeCode = jwtDecode(token);
            const user_id = decodedToken.sub;
            setLoadingImage(true);
            axiosInstance.post(UPLOAD_IMAGE_URI + user_id, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    keyImage: keyImage,
                    user_id: user_id
                },
            })
                .then(response => {
                    const data = response.data;
                    imageUrls[index] = data.image_url;
                    setOpen(true);
                    setLoadingImage(false);
                })
                .catch(error => {
                    console.error('Error sending image to backend', error.response?.data?.error);
                    if (error.response && error.response.data) {
                        setError(error.response.data.error);
                    }
                    setLoadingImage(false);
                    event.target.value = '';
                    return;
                });
        } else {
            console.error('Error: Unable to create blob from canvas');
            setLoadingImage(false);
        }
        event.target.value = '';
    };

    const handleRequestRepeat = () => {
        setResult({title: '',
            response: ''});
        setTextParts('');
        setRepeatSection(false);
        /*setError('');

*/
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        const decodedToken: JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        const payload = {
            prerequirement: repeatData?.prerequirement,
            imageUrls: imageUrls,
            checkedValues: checkedValues,
            sort_key: repeatData?.sort_key,
            key_origin: repeatData?.key_origin,
            action: 'text-help-repeat',
            user_id: user_id,
        };

        sendMessage(JSON.stringify(payload));

        setImageUrls([]);
    };

    const isAllUnchecked = () => {
        return checkedValues.every(value => value === false);
    };

    const handleDeleteImage = (deleteIndex:number) => {
        setCheckedValues((prevCheckedValues) => {
            const newCheckedValues = [...prevCheckedValues];
            newCheckedValues[deleteIndex] = !newCheckedValues[deleteIndex];
            return newCheckedValues;
        });
    };

    const downloadImage = (imageUrl:string, index:number) => {
        if (imageUrl) {
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = `image${index}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const repeatDataUpload = () => {
        setRepeatSection(true);
        //if (repeatData!.keys_images.length === 0 && /\.(png|jpg|jpeg|gif)$/i.test(repeatData!.key_origin)) {
        //}
    };

    function extractPathFromS3Url(url: string, index:number): string | null {
        const regex = /^https?:\/\/[^/]+\/(.+?)\?/;
        const match = url.match(regex);
        const value = match ? match[1] : null;
        return value;
    }

    const action = (
        <React.Fragment>
            <Button color="secondary" onClick={handleSnackbarClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleIconClick = () => {
        setShowRetryIndication(!showRetryIndication);
    };

    const processText = (text: string) => {

        const codeParts = text.replace(/```(\w+)([\s\S]*?)```/gs, (match, lang, code) => {
            return `<pre class="language-${lang}"><code className="language-${lang}">${code}</code></pre>`;
        });

        const code = codeParts.replace(/`([\s\S]*?)`/gs, (match, p1) => {
            return `<code>${p1}</code>`;
        });

        const styledText = code.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
            return `<strong>${p1}</strong>`;
        });

        const textWithLineBreaks = styledText.replace(/(\n)/g, '<br />');

        return textWithLineBreaks;
    };

    const isRtl = language === 'ar';

    // @ts-ignore
    return (
        <>
            <InputTextContainer hidden={kind !== 'other'}>
                <InputText hidden={kind !== 'other'}
                           type="text"
                           id="zoneTexte"
                           value={otherRequest}
                           onChange={handleOtherRequestChange}
                           placeholder={t('other_example') as string}
                />
            </InputTextContainer>
            <ButtonsContainer>
                <Button
                    onClick={() => fileInputRef.current?.click()} // Trigger file input click on button click
                    hidden={recording}
                    disabled={buttonDisable || errorMessage}
                >
                    {t('upload')}
                </Button>
                <input
                    type="file"
                    accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, image/*"  // Adjust accepted file types as needed
                    capture="environment" // Adjust the capture attribute as needed
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                />

            </ButtonsContainer>
            <span hidden={!errorMessage} style={{color: 'red'}}>{t('errorInternetConnection')}</span>

            {error && (
                <ResultContainer>
                    <ErrorMessage>{error}</ErrorMessage>
                </ResultContainer>
            )}

            {result && (
                <ResultContainer>
                    <ResultHeading>{t('result')}</ResultHeading>

                    <div style={{textAlign: 'right'}}>
                        {imageUrls.length !== 0 && (
                            <FooterItem>
                                <CopyToClipboard
                                    text={textParts.replace(/<(?!br\s*\/)[^>]*>/g, '').replace(/<br\s*\/?>/g, '\n')}>
                                    <CopyIcon style={{
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                        color: '#4a148c',
                                        maxHeight: '30px'
                                    }}></CopyIcon>
                                </CopyToClipboard>
                                <FooterLabel>{t('copy')}</FooterLabel>
                            </FooterItem>
                        )}
                    </div>

                    <MathJaxContext>
                        <MathJax dynamic>
                            <div ref={mathJaxRef} dangerouslySetInnerHTML={{__html: processText(textParts)}}
                                 style={{direction: isRtl ? 'rtl' : 'ltr'}}></div>
                        </MathJax>
                    </MathJaxContext>
                    {imageUrls.length !== 0 && (
                        <SatisfiedMessage>{t('unsatisfactoryResultMessage')}
                            <RepeatIcon onClick={repeatDataUpload} style={{
                                marginRight: '10px', cursor: 'pointer',
                                color: '#4a148c', maxHeight: '30px', verticalAlign: 'middle'
                            }}>
                            </RepeatIcon>
                        </SatisfiedMessage>
                    )}
                    {imageUrls.length !== 0 && (
                        <div>
                            <span style={{marginRight: '10px'}}>{t('howItWork')}</span>
                            <InfoIcon onClick={handleIconClick} style={{cursor: 'pointer'}}>
                            </InfoIcon>
                            <Modal
                                open={showRetryIndication}
                                onClose={() => setShowRetryIndication(false)}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                        width: '80%',
                                    }}
                                >
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={() => setShowRetryIndication(false)}
                                        aria-label="close"
                                        style={{position: 'absolute', top: '10px', right: '10px', color: 'red'}}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                    <h2 id="modal-title" style={{color: 'white'}}>.</h2>
                                    <p id="modal-description"
                                       dangerouslySetInnerHTML={{__html: t('retryIndication') || ''}}
                                       style={{direction: isRtl ? 'rtl' : 'ltr'}}></p>
                                </div>
                            </Modal>
                        </div>
                    )}
                    {repeatSection && (
                        <table style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                            <thead>
                            <tr>
                                <th></th>
                                <th dangerouslySetInnerHTML={{__html: t('downloadForEdition') || ''}}/>
                                <th dangerouslySetInnerHTML={{__html: t('resendImage') || ''}}/>
                                <th dangerouslySetInnerHTML={{__html: t('delete') || ''}}/>
                            </tr>
                            </thead>
                            <tbody>
                            {imageUrls.map((imageUrl, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <DownloadIcon onClick={() => downloadImage(imageUrl, index)}/>
                                    </td>
                                    <td>
                                        <UploadIcon onClick={() => imageInputRefs[index]?.current?.click()}/>

                                        <input
                                            type="file"
                                            accept="image/*"
                                            capture="environment"
                                            onChange={(event) => handleImageUpload(event, extractPathFromS3Url(imageUrl, index), index)}
                                            ref={imageInputRefs[index] as React.MutableRefObject<HTMLInputElement>}
                                            style={{display: 'none'}}
                                        />
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={checkedValues[index]}
                                               onChange={() => handleDeleteImage(index)}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                    {
                        loadingImage &&
                        <LoadingContainerWait>
                            <CircularProgress/>
                            <LoadingText>
                                {t('imageLoading')}
                            </LoadingText>
                        </LoadingContainerWait>
                    }
                    {repeatSection && (
                        <div style={{alignItems: 'center'}}>
                            <Button style={{display: 'block', margin: '20px auto'}}
                                    onClick={handleRequestRepeat}
                                    disabled={isAllUnchecked()}>
                                {t('retry')}
                            </Button>
                        </div>
                    )
                    }
                </ResultContainer>
            )
            }
            {
                loadingResult &&
                <LoadingContainerWait>
                    <CircularProgress/>
                    <LoadingText>
                        {kind != null ? t('kind', {kind: t(kind)}) : ''}
                    </LoadingText>
                </LoadingContainerWait>
            }
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={t('imageUploadSuccess')}
                action={action}
            />
        </>
    );
};

export default UploadToMedia;
